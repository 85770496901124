import * as React from 'react'
import { graphql } from 'gatsby'
import DefaultLayout from 'layouts/Default'
import Block from 'components/blocks/Block'

export default function PageResource({ data: { pg } }) {
	return (
		<DefaultLayout {...pg.seo} headerTitle={pg.title} noIndex={pg.noIndex}>
			{pg.blocks?.map((block) => (
				<Block {...block} key={block?._key} />
			))}
		</DefaultLayout>
	)
}

export const query = graphql`
	query PageResourceTemplate($id: String) {
		pg: sanityPageResource(id: { eq: $id }) {
			noIndex
			title
			blocks {
				...hero
				...richtextModule
				...emailSignupForm
			}
			seo {
				title
				description
			}
		}
	}
`
